import React, {useEffect, useState, Alert, useContext,Button, useRef} from "react";
import './Home.css';
import Logo from "./images/Logo.png";
import Union from "./images/Union.png";
import Vector from "./images/Vector.png";
import c1 from "./images/c1.png";
import c2 from "./images/c2.gif";
import c3 from "./images/c3.gif";
import c4 from "./images/c3.png"
import {  BrowserRouter as Router, Route, Switch,Link, Routes, useNavigate} from "react-router-dom";
import DripGardenTools from "./Gardenn";
import Scheduler from "./Scheduler";
import WalletConnectClient from "@walletconnect/client";
import Web3 from "web3";
import { $Contract, $Contract_LP } from './contracts/contract';
import axios from 'axios';
import Modal from "./Components/Modal";
import { module } from "browserify/lib/builtins";
import UserContext, { useUserContext }  from "./UserContext";



const $PCS_API = "https://api.pancakeswap.info/api/v2/tokens/";

            

function Home(){
 
// const {user,setUser,Disconnect} = useContext(UserContext);

const{user,setUser, data, setData, drip_busd, setdrip_busd, Disconnect}= useContext(UserContext);
console.log(user);

// useEffect(()=>{
//   const storeddata = localStorage.getItem('data');
//   const storeduser = localStorage.getItem('user');
//   const storedrip = localStorage.getItem('drip');

//   if(storeddata){
//     setUser(JSON.parse(storeduser));
//     setData(JSON.parse(storeddata));
//     setdrip_busd(JSON.parse(storedrip));
    

//   }
// }, []);
// useEffect(()=>{

//   if(user.walletexits === true){
//   localStorage.setItem('data', JSON.stringify(data));
//   localStorage.setItem('user', JSON.stringify(user));
//   localStorage.setItem('drip', JSON.stringify(drip_busd));

//   }
//   else{
//   localStorage.setItem('millie', 'you won!')
//   }
 
//  },[user, data,drip_busd])



 
const [modalOpen, setModalOpen] = useState(false);


const [hover, setHover] = useState();

  const handleMouseIn = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };


  const btn = document.getElementById('mymodal');
 
if (btn) {
  btn.addEventListener('click', () => {
    console.log('btn clicked');
  });
}


      
  


 return( 

    <div className="main">
       
        <div className="thegrid">

    <div className="Header" >

    <Link to="/">
        <img className="ttheLogo" src={Logo}/>
        </Link>
        <div className="buttons" style={{marginRight:"16px"}}>
        <div className="Discord" onClick={()=> window.location.href='https://discord.com/invite/wvY68gewfP'}>
        <div className="Discord1">Discord</div>
        <img className="Discord2"src={Union}/>
        </div>
        </div>
        {user.walletexits?
              <div className="buttons">
              <div className="Wallet" onClick={() => {
              }} >
       <div className="Wallet1" onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} onClick={Disconnect}>
        {hover ? "Disconnect Wallet" : "Wallet Connected"}

      </div>
              
              <img src={Vector}/>
      
              </div>
              </div>
              :
                    <div className="buttons">
                    <div className="Wallet" onClick={() => {
                      setModalOpen(true);
                    }} >
                    <div className="Wallet1">Wallet</div>
                    <img clasName="Wallet2"src={Vector}/>
                    {modalOpen && <Modal onClose={()=>setModalOpen(false)}  />}
            
                    </div>
                    </div>  }
                    
                   
  

    </div>


          <div className="box" >
          <div className="boxheader">Tracker Whale NFTs</div>
            <div className="box11">
            <div className="boxm">
            <p>Our very own Crypto Passive Income Tracker Whale NFTs.</p>
            <button>Coming Soon!</button>
            </div>
            <img className="c1"src={c1}/>

            </div>
   
          </div>
          <div className="box" >
          <div className="boxheader">Drip Garden Tools </div>
          <div className="box12">
            <div  className="boxm"> 
            <p>Running your Drip Garden Farm has never been so fun.</p>
            {user.walletexits?
                                   <Link to="/DripGardenTools">
                                   <button type="button">
                                        Go to >
                                   </button>
                               </Link>
             
              
                    :
                    <Link to="/GardenAwaits">
                    <button type="button">
                         Go to >
                    </button>
                </Link>
            
          }


            </div>
          <img src={c2}/>
            </div>
            
          </div>

          <div className="box">
          <div className="boxheader">SafeMoon Tools </div>
          <div className="box13">
            <div  className="boxm">
           <p>Reflections could be the rocket that takes you safely to the moon.</p>
            <button>Coming Soon!</button>
           </div>
           <img className="c4" src={c4}/>
          </div>
          </div>

          <div className="box">
          <div className="boxheader">More Projects Coming!</div>
          <div className="box14">
            <div  className="boxm">
           <p>Help fund new tools for your favorite Crypto Passive Income Project.</p>
           <Link to="/NextProject">
                    <button type="button">
                         Go to >
                    </button>
                </Link>
           </div>
           <img className="c3" src={c3}/>
          </div>
          </div>




          <div className="frontfooter">
            <div>Copyright © 2022 Dewq Finance All Rights reserved.</div>
 
 <Link to="/Garden">Privacy Policy</Link>
 <Link to="/">Terms & Conditions</Link>
            </div>


      </div> 
      
      
    </div> 





  );

};
export default Home;


