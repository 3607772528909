import React, {useContext, useState, useEffect} from "react";
import Logo1 from "./images/Logo1.png";
import Union from "./images/Union.png";
import Vector from "./images/Vector.png";
import Emaill from "./images/Emaill.png";
import Wallet from "./images/Wallet.png";
import Discord from "./images/Discord.png";
import Group from "./images/Group.png";
import Group2 from "./images/Group2.png";
import Group92 from "./images/Group92.png";
import Group206 from "./images/Group206.png";
import Frame from "./images/Frame.png";
import Frame3 from "./images/Frame3.png";
import Frame1 from "./images/Frame1.png";
import Union2 from "./images/Union2.png";
import Web3 from "web3";
import moment from "moment";

import UserContext, { useUserContext }  from "./UserContext";
import "./DripGardenTools.css";
import Modal from "./Components/Modal";

import './garden.css';

import { ABI } from './contracts/contractABI';
import { $Contract, $Contract_LP } from './contracts/contract';
import Scheduler from './Scheduler';
import axios from 'axios';
import {WalletLinkConnector} from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from '@web3-react/core';
import wallet from 'wallet';
import {  BrowserRouter as Router, Route, Switch,Link, Routes, useNavigate} from "react-router-dom";

const $PCS_API = "https://api.pancakeswap.info/api/v2/tokens/";

export default function Garden() {  

  const{user,setUser, data, setData, drip_busd, setdrip_busd, Disconnect, currentLp, currentdollars, bydayDollars, bydayLP}= useContext(UserContext);
  console.log(user);


  useEffect(()=>{
    const storeduser = localStorage.getItem('user');
    const storeddata = localStorage.getItem('data');
    const storedrip = localStorage.getItem('drip');
  
    if(storeduser){
      setUser(JSON.parse(storeduser));
      setData(JSON.parse(storeddata));
      setdrip_busd(JSON.parse(storedrip));
      

    }
  }, []);
  useEffect(()=>{
  
    if(user.walletexits === true){
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('data', JSON.stringify(data));
    localStorage.setItem('drip', JSON.stringify(drip_busd));
  
    }
    else{
    localStorage.setItem('millie', 'you won!')
    }
   
   },[user, data,drip_busd])

  useEffect(()=>{
    updatedrip_busd()   

  },[])
  useEffect(()=>{
    getdata()   

  },[])
  useEffect(() => { setCurrent(currentLp)}, [currentLp] )
  useEffect(() => { setDaily(bydayLP)}, [bydayLP] )

  
  const [pointercolor, setPointer] =useState();
  const [wordcolor, setWordcolor] = useState();
  const [pointercolor1, setPointer1] =useState();
  const [pointercolor2, setPointer2] =useState();
  const [pointercolor3, setPointer3] =useState();
  const [pointercolor4, setPointer4] =useState();
  const [pointercolor5, setPointer5] =useState();
  const [pointercolor6, setPointer6] =useState();



  const getdata = async () =>{

    //get wallet address balance
  
    let walletbalance = await $Contract_LP.methods.balanceOf(user.wallet).call()
    const dwalletbalance = parseFloat(walletbalance).toFixed(2)
    console.log(walletbalance)
  //plants
    let userplants = await $Contract.methods.hatcheryPlants(user.wallet).call()
    let uuserplants = parseInt(userplants);
    
    let uselpperday = await $Contract.methods.calculateSeedSell(parseFloat(userplants) * 86400).call()
     let user_lp_per_dayy = ( parseFloat(uselpperday)* 0.95)/1000000000000000000
  
    //seeds per day
    let seedsperday = userplants * 86400
    console.log(seedsperday)
  
  
    //seeds per plant
  
    let seedsperplant = await $Contract.methods.SEEDS_TO_GROW_1PLANT().call()
        console.log(seedsperplant)
    //seeds lost
  
    
    // user seeds
  let userseeds = await $Contract.methods.getUserSeeds(user.wallet).call()
    
    console.log(userseeds)
  
    // seeds ready
    let planstsready = Math.floor(userseeds / seedsperplant)
   
    console.log(planstsready)
  
  //seeds lost
   let seedslost = userseeds - (planstsready * seedsperplant)
   console.log(seedslost)
  
   // seeds needed
  let seedsneeded = seedsperplant - seedslost
   console.log(seedsneeded)
  
  //seeds available
  
  let userlp = await  $Contract.methods.calculateSeedSell(userseeds).call()
     let etheruserlp =  Web3.utils.fromWei(userlp, 'ether');
     let calculateduserlp = etheruserlp * 0.95
     const cuserlp = parseFloat(calculateduserlp).toFixed(2)
  
  //
  
  let  seeds_per_minute = (seedsperday / 24) / 60;
  let plant_next_minute = (seedsneeded) / (seeds_per_minute);
  let plant_full_minute = seedsperplant / seeds_per_minute;
  
  
  
  
    setData(prevState => ({
      ...prevState,
        
      user_balance : dwalletbalance,
      seeds_per_day: seedsperday,
      seeds_per_plant: seedsperplant,
      seeds_lost: seedslost,
      seeds_needed: seedsneeded,
      plants_ready: planstsready,
      user_lp: cuserlp,
      user_lp_per_day: user_lp_per_dayy,
      plant_full_minute
       
   }
   ));
  
   setUser(prevState => ({
    ...prevState,
      plants: uuserplants,
      seeds: userseeds
   
     
  }
  ));
  
  }

  const updatedrip_busd = async()=> {

  const thecontract_balance = await $Contract.methods.getBalance().call()
  let balanceinEth = Web3.utils.fromWei(thecontract_balance, 'ether')
  let truncatedbalance =  Math.trunc(balanceinEth);
  console.log(truncatedbalance)


  if( truncatedbalance >= 350000){
    setPointer("white")
    setWordcolor("black") 
  }
  if( truncatedbalance<=350000 && truncatedbalance >= 300000){
    setPointer1("white") 
  }
  if( truncatedbalance<=300000 && truncatedbalance >= 250000){
    setPointer2("white") 
  }
  if( truncatedbalance<=250000 && truncatedbalance >= 200000){
    setPointer3("white") 
  }
  if(truncatedbalance<=200000 &&  truncatedbalance >= 150000){
    setPointer4("white") 
  }
  if( truncatedbalance<=150000 && truncatedbalance >= 100000){
    setPointer5("white") 
  }
  if(truncatedbalance<=100000  &&   truncatedbalance >= 50000){
    setPointer6("white") 
  }


  let seedsell = await $Contract.methods.calculateSeedSell(2592000).call()  
  let rate_plant_lpp =(seedsell * 0.95)/1000000000000000000
  console.log(rate_plant_lpp)



  let getsupply = await $Contract_LP.methods.totalSupply().call() 
  let lsupply = parseFloat (Web3.utils.fromWei(getsupply, 'ether')).toFixed(18)

    
    let gettingreserve = await $Contract_LP.methods.getReserves().call() 

        let drip_reserve = parseFloat(gettingreserve._reserve0)/1000000000000000000
        
        console.log(drip_reserve)
        let busd_reserve =parseInt(gettingreserve._reserve1)/1000000000000000000
        console.log(busd_reserve )

      let tokens = await $Contract_LP.methods.token0().call() 
 
      let tkns = await axios.get($PCS_API + tokens)
      console.log(tkns)
     let  drip_price= parseFloat(tkns.data.data.price)
     console.log(drip_price)

    let  token = await $Contract_LP.methods.token1().call() 
    console.log(token)

      let tttokens = await axios.get($PCS_API + token)
      console.log(tttokens)
      let busd_price = parseFloat(tttokens.data.data.price)
      console.log(busd_price)

    let lp_ratio = 1 / lsupply
    console.log(drip_reserve)
    let Price = (drip_reserve * lp_ratio * drip_price)
      + (busd_reserve * lp_ratio * busd_price);
      console.log(Price)

  console.log(user.wallet)
    setdrip_busd(prevState => ({
      ...prevState,
      supply:lsupply,
      price:Price,

   }
   )); 
  setData(prevState=>({
    ...prevState,
    contract_balance: truncatedbalance,
    rate_plant_lp: rate_plant_lpp
  }));
 
  }
    





const [hover, setHover] = useState();

const handleMouseIn = () => {
  setHover(true);
};

const handleMouseOut = () => {
  setHover(false);
};


 
const [modalOpen, setModalOpen] = useState(false);

function hourToTime(hour) {
  let days = moment.duration(hour, 'hours').days() + "d ";
  if (days == "0d ") { days = ""; };
  let min = moment.duration(hour, 'hours').minutes();
  if (min <= 9) { min = "0" + min; }
  return days + moment.duration(hour, 'hours').hours() + ":" + min;
}



const [color,setColor]=useState("#239D3E")

const [currentvalue, setCurrent] = useState(currentLp)
const [dailyvalue, setDaily] = useState(bydayLP)

    
const colorchange = ()=>{
  
  if (color==="#239D3E"){
    setColor("#2C59B3")
    setColor2 ("#239D3E")
    setCurrent (currentdollars)
    setDaily(bydayDollars)
    
  }
  else if(color==="#2C59B3"){
    setColor("#239D3E")
    setColor2("#2C59B3")
    setCurrent(currentLp)
    setDaily (bydayLP)
  }
     
  }

const [color2, setColor2] = useState('#2C59B3')

const colorchange2 =()=>{
  if( color2=== "#239D3E"){
    setColor2("#2C59B3");
    setColor("#239D3E")
    setCurrent(currentLp)
    setDaily(bydayLP)
  }
  else if (color2 === "#2C59B3"){
    setColor2("#239D3E")
    setColor("#2C59B3")
    setCurrent(currentdollars)
    setDaily(bydayDollars)

  }

}
 
 
console.log(typeof(user.seeds))

  
    return(
        <div className="All">   
        <div className="thisgrid">
            <div className="theader">
              
            <Link to="/">
        <img className="Logo" src={Logo1}/>
        </Link>
                
                <div className="fleft">
                <div className="buttons" >
                <div className="Discord">
                <div className="Discord1" onClick={()=> window.location.href='https://discord.com/invite/wvY68gewfP'}>Discord</div>
                <img className="Discord2" src={Discord}/>
                </div> 
                </div>

                {user.walletexits?
              <div className="buttons">
              <div className="Wallet" onClick={() => {
              }} >
       <div className="Wallet1" onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} onClick={Disconnect}>
        {hover ? "Disconnect Wallet" : "Wallet Connected"}

      </div>
              
              <img src={Vector}/>
      
              </div>
              </div>
              :
                    <div className="buttons">
                    <div className="Wallet" onClick={() => {
                      setModalOpen(true);
                    }} >
                    <div className="Wallet1">Wallet</div>
                    <img clasName="Wallet2"src={Vector}/>
                    {modalOpen && <Modal onClose={()=>setModalOpen(false)}  />}
            
                    </div>
                    </div>  }
                
                      
   
           


                <div className="buttons" >
                <div className="Email">
                <div className="Email1">Email</div>
                <img className="Email2" src={Emaill}/>
                </div> 
                </div>

  
                </div>


            </div>
            <div className="boxx">
              <div>
              <div className="gp">Plants grown</div>
              <div className="g1"><div className="g11">{(parseInt(user.plants)).toLocaleString()}</div></div>
              </div>
              <img className="Group"src={Group} />
            </div>

            <div className="boxx1">
              <p className="gpT" >Total Farm Value</p>
              <div className="bx1">
                <div>
                <p>
                If plants were sellable,<br/>this is the value of all <br/>grown plants.
                </p>
                <div className="bx11"><div className="g11">{(parseFloat(user.plants)* parseFloat(data.rate_plant_lp)* parseFloat(drip_busd.price)).toFixed(2)}  $</div></div>
                </div>
              <img className="Frame"src={Frame} />
              </div>

            </div>

            <div className="boxx12">
              <div >
              
              <div className= "fsc">
              <div className="fscc">
              <div className="gp">Contract Balance</div>
              <div className="fccc">
              <div className="fc">
                <div className="cc1">
                  <div className="cc11">7 days</div>
                </div>
                <div className="cc2">
                <div className="cc11">1 Month Ago</div>
                 </div>
                <div className="cc3"> 
                <div className="cc11">6 Months Ago</div>
                </div>
                <div className="cc4"> 
                <div className="cc11">1 Year Ago</div>
                </div>
                <div className="ccc">This is a measure of your earnings. Click on the time periods and look at your stats </div>
                <div className="cccc"><div className="cccc1">Now {(parseInt(data.contract_balance)).toLocaleString()} LP </div></div>
              </div>
              <div className="sc">
                <div className="sc1" style={{background:pointercolor}}>
                <div className="u1" style={{color:wordcolor}}>350k</div> 
                <img className="u2" src={Union2}/>
                </div>
                <div className="sc1" style={{background:pointercolor1}}>
                <div className="u1">300k</div> 
                <img className="u2" src={Union2}/>
                </div>
                <div className="sc1" style={{background:pointercolor2}}>
                <div className="u1">250k</div> 
                <img className="u2" src={Union2}/>
                </div>
                <div className="sc1" style={{background:pointercolor3}}>
                <div className="u1" >200k</div> 
                <img className="u2" src={Union2}/>
                </div>
                <div className="sc1" style={{background:pointercolor4}}>
                <div className="u1">150k</div> 
                <img className="u2" src={Union2}/>
                </div>
                <div className="sc1" style={{background:pointercolor5}}>
                <div className="u1">100k</div> 
                <img className="u2" src={Union2}/>
                </div>
                <div className="sc1" style={{background:pointercolor6}}>
                <div className="u1">50k</div> 
                <img className="u2" src={Union2}/>
                </div>
              </div>
              </div>
              </div>
              <img className="Group2" src={Group2}/>
              </div>
             
              </div>
            </div>

            <div className="boxx13">
              <div className="tfv">            
              <p className="gpp">Value/LP Station</p>
              <div className="tfv1">Value that your farm is pumping out.</div>
              <div className="tfv2" onClick={()=> window.location.href='https://theanimal.farm/garden'}><div className="g111"></div>TheAnimal.Farm</div>
              </div> 
              <div className="Group92" style={{ backgroundImage:`url(${Group92})` }}>
              <div className="drip"><div className="drip1">{drip_busd.price.toFixed(4)}$</div> <div className="drip2">DRIP/BUSD</div></div>

            

              <div className="drip3">
              <div className="dl">
              <button className="lbutton" style={{background:color}} onClick={colorchange}><div className="LP" >LP</div></button>
              <button className="dbutton" style={{background:color2}} onClick={colorchange2}> <div className="LP">$</div></button>
              </div>
                <div className="drip33"><div className="cd">Current: {(currentvalue) } </div> </div>
                <div className="drip33"> <div className="cd">Daily : {dailyvalue}</div></div>
              </div>
              </div>
            </div>

            <div className="boxx14">
            <div className="gp">Seed Stats </div>
              <div className="f3">
              <div>
              <div className="f33">2.592M seeds = 0.28$ = 0.015 LP
              <br/>
              1 Plant = 86.4k Seeds/Day
              </div>
              <div className="f333"><div className="g11">{(parseInt(data.seeds_per_day)).toLocaleString()}</div></div>
              </div >
              <div className="Frame1" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f35">Sprouted 21:20</div>
              </div>
              </div>
            </div>

            <div className="boxx15">
      
            <div className="b5">
              <div className="b555">
              <div className="gp">Seed Production</div>
                <div className="b55"><div className="b551"> Seeds Available:  {(parseInt(user.seeds)).toLocaleString()}</div></div>
                <div className="b55"><div className="b551">Seeds to Next Plant: {(parseInt(data.seeds_needed)).toLocaleString()} </div></div>
                <div className="b55"><div className="b551">Seeds Lost if Planted:  -{(parseInt(data.seeds_lost)).toLocaleString()} </div></div>

              </div>
            <img className="Group206" src={Group206}/>
            </div>
     
            </div>

      <div className="boxx16">
        <div className="t1">
          <div className="t11">
          <p className="gp">New Plants Grown</p>
            <div className="t111"> 
              <div>
              <div className="t113">Plants ready to harvest<br/> or <br/> add to your farm.</div>
              <div className="t1111"><div className="g112">{(parseInt(data.plants_ready)).toLocaleString()}</div></div>
              </div >
              <img className="Frame3" src={Frame3} />
            </div>
           
          </div>

          <div className="t12">
            <div className="t122">
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>

            </div>
            <div className="t122">
            <div className="t1222">
            <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
            </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
                              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>

            </div>



          </div>


        </div>
        <div className="last">
        <div className="t1222">
        <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
        </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              <div className="t1222">
              <div className="Frame2" style={{ backgroundImage:`url(${Frame1})` }}>
                <div className="f36">03:17/ 21:20</div>
              </div>
              </div>
              

        </div>
 

      </div>

        </div>
        <div className="footer">
            <div>Copyright © 2022 Dewq Finance All Rights reserved.</div>
 
 <Link to="/Garden">Privacy Policy</Link>
 <Link to="/">Terms & Conditions</Link>
            </div>
        </div>  
    );
}