import React, {useContext, useRef} from "react";
import "./Modal.css";
import Web3 from "web3";
import { useState, createContext, useEffect } from "react";
import { Alert, Hide } from "@chakra-ui/react";
import Home from "../Home";
import UserContext, { useUserContext }  from "../UserContext";
import useOnclickOutside from "react-cool-onclickoutside";
import  Wallet from "../images/Wallet.png";
import c5 from "../images/c5.gif";


 
function Modal( {onClose}) {
  const box = useRef(null);
  useOutsideAlerter(box);

  const{handleEthereum, checkWallet,} = useUserContext();

  function useOutsideAlerter(ref) {
    useEffect(() => {
   
      // Function for click event
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose()
        }
      }
   
      // Adding click event listener
      document.addEventListener("click", handleOutsideClick);
      return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref]);
  }

  
  
const [modalOpen, setModalOpen] = useState(false);


const [hover, setHover] = useState();

  const handleMouseIn = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };


  const btn = document.getElementById('mymodal');
 
if (btn) {
  btn.addEventListener('click', () => {
    console.log('btn clicked');
  });
}


 
     
   
    
  return (
      <div className="modalContainer" id="mymodal" ref={box} >
        <div className="WalletAddress">
        <input className="AddressInput" placeholder="Enter your wallet address here" onChange={checkWallet} />
        </div>
        <div style={{ backgroundImage:`url(${c5})`, backgroundSize:"540px 195px", backgroundRepeat:"no-repeat", width:"540px", height:"195px"}}> 
        <p className="or">OR</p>
        

        <div className="tthebutton" >
                <div className="Walletb">
                <div className="Walletc" onClick={handleEthereum}>Wallet connect</div>
                <img className="Walleti" src={Wallet}/>
                </div> 
                </div>
                

                </div>
    

      </div>
      
  );
}

export default Modal;




