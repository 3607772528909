import React,{useState} from "react";
import './App.css';
import {Route,Routes, useNavigate} from "react-router-dom";
import Home from "./Home";
import Garden from "./DripGardenTools";
import Scheduler from "./Scheduler";
import {UserProvider} from "./UserContext";
import Modal from "./Components/Modal";
import Before from "./GardenAwaits";
import NextProject from "./NextProject";




function App(){
  


  
return(
	
  
  <div>
  
  <UserProvider>
  <Routes>
  
  <Route path="/DripGardenTools" exact element={<Garden/>}/>
  <Route path="/NextProject" exact element={<NextProject/>}/>
  <Route path="/Modal" exact element={<Modal/>}/>
  <Route path="/Scheduler" exact element={<Scheduler/>}/>
  <Route path="/GardenAwaits" exact element={<Before/>}/>
  <Route path="/" exact element={<Home/>}/>
  </Routes>
  </UserProvider>
  
  

  
  

     </div>



   
  


   

  );

}
export default App;



