import React, {useEffect, useState, Alert, useContext,Button, useRef} from "react";
import Logo from "./images/Logo.png";
import Union from "./images/Union.png";
import Vector from "./images/Vector.png";
import {  BrowserRouter as Router, Route, Switch,Link, Routes, useNavigate} from "react-router-dom";
import Modal from "./Components/Modal";
import UserContext, { useUserContext }  from "./UserContext";
import "./BeforeGarden.css";
import Await from "./images/Await.png";
import addressinput from "./images/addressinput.png";
import Wallet from "./images/Wallet.png";
import Web3 from "web3";
import axios from "axios";


export default function Before(){
  let navigate = useNavigate();
  const{handleEthereum, checkWallet} = useUserContext();
    const{user,setUser}= useContext(UserContext);
    console.log(user);
    
    
  const [modalOpen, setModalOpen] = useState(false);
  
  
  const [hover, setHover] = useState();
  
    const handleMouseIn = () => {
      setHover(true);
    };
  
    const handleMouseOut = () => {
      setHover(false);
    };
  
    function Disconnect(){
      setUser({walletexits:false})
      setModalOpen(false);
    }
    const btn = document.getElementById('mymodal');
   
  if (btn) {
    btn.addEventListener('click', () => {
      console.log('btn clicked');
    });
  }
 



    return(
        <div className="themain">
            <div className="bheader">
    
        <Link to="/">
        <img className="theLogo" src={Logo}/>
        </Link>
    
        <div className="buttons" style={{marginRight: "16px"}}>
        <div className="Discord" onClick={()=> window.location.replace('https://discord.com/invite/wvY68gewfP')}>
        <div className="Discord1">Discord</div>
        <img className="Discord2"src={Union}/>
        </div>
        </div>
        {user.walletexits?
              <div className="buttons">
              <div className="Wallet" onClick={() => {
              }} >
       <div className="Wallet1" onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} onClick={Disconnect}>
        {hover ? "Disconnect Wallet" : "Wallet Connected"}

      </div>
              
              <img src={Vector}/>
      
              </div>
              </div>
              :
                    <div className="buttons">
                    <div className="Wallet" onClick={() => {
                      setModalOpen(true);
                    }} >
                    <div className="Wallet1">Wallet</div>
                    <img clasName="Wallet2"src={Vector}/>
                    {modalOpen && <Modal onClose={()=>setModalOpen(false)}  />}
            
                    </div>
                    </div>  }
            </div>
            <div className="awaits">Your Garden Awaits... </div>
            <div className="body">
                <div className="innerbody" >
                </div>
            </div>
            <div className="connection">
          
             <input className="theAddressInput" placeholder="Enter your wallet address here" onChange={checkWallet} />
            
            <div className="theor">Or</div>

            <div className="thebutton" >
                <div className="Walletb">
                <div className="Walletc" onClick={handleEthereum}>Wallet connect</div>
                <img className="Walleti" src={Wallet}/>
                </div> 
                </div>
            </div>
            <div className="thefooter">
            <div>Copyright © 2022 Dewq Finance All Rights reserved.</div>
 
 <Link to="/">Privacy Policy</Link>
 <Link to="/">Terms & Conditions</Link>
            </div>

            {user.walletexits? navigate("/DripGardenTools"): null}
            


        </div>
    )
}