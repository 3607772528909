import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router} from 'react-router-dom';
import { render } from 'react-dom';
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";
import history from './history';
function getLibrary(provider) {
  return new Web3Provider(provider);
}


const rootElement = document.getElementById("root");
render(

     <Web3ReactProvider getLibrary={getLibrary}>
     <Router history={history}>
     <App />
     </Router>
     </Web3ReactProvider>
  




,
  rootElement
   
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
