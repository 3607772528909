import './garden.css';
import Web3 from 'web3';
import React,  {useEffect, useState, Alert, useContext} from 'react';

import { ABI } from './contracts/contractABI';
import { $Contract, $Contract_LP } from './contracts/contract';
import Scheduler from './Scheduler';
import axios from 'axios';
import {WalletLinkConnector} from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from '@web3-react/core';
import UserContext from './UserContext';
import wallet from 'wallet';

const $PCS_API = "https://api.pancakeswap.info/api/v2/tokens/";

function DripGardenTools() {   
  const{user, data, drip_busd, setUser, setData, setdrip_busd, updatedrip_busd, handleEthereum, checkWallet}= useContext(UserContext);
  console.log(user.wallet);
  useEffect(()=>{
    
    updatedrip_busd()
    

  },[])
  

  
const [modalOpen, setModalOpen] = useState(false);
  const [hover, setHover] = useState();

  const handleMouseIn = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };
  function Disconnect(){
    setUser({walletexits:false})
  }

  return (
    <div className='grid'>
      {user.walletexits 
      ?<header className="App-header">
        <div>
        <button onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} onClick={Disconnect}>
        {hover ? "Disconnect Wallet" : "Wallet Connected"}
        </button>
        </div>

            </header>  
      :   <div className='head'>
            <div className='title'>
            Welcome! Paste your PUBLIC wallet address in the field below and click anywhere after. This will act as a read only application. You can also connect your wallet for additional functionality but it is NOT REQUIRED to use this site. We will never ask your seed phrase or private key, NEVER give those anywhere!
            </div>
            <div className="connect">
            {user.walletexits?
              <button>Connected Wallet</button>
              :
              <button onClick={handleEthereum}>Connect Wallet</button>
    }
            
            

              </div>

         
          </div> 
          
      }
  
       
   <table className="hometable">
     <tbody>
       <tr>



           <td id='fcolumn'>Contract Balance</td>
           <td id='tcolumn'>{data.contract_balance}LP</td>
           <td id='tcolumn'>DRIP/BUSD <br/>{drip_busd.price.toFixed(4)}</td>

       </tr>

       <tr>
           <td id='fcolumn'> 1 Plant Info</td>
           <td id='tcolumn'>{data.seeds_per_plant}  seeds make 1 Plant <br/> 1 plant produces 86.4k seeds / day</td>
           <td id='tcolumn'>2.592M seeds =<br/>{((data.rate_plant_lp)*(drip_busd.price)).toFixed(2) }$ = {data.rate_plant_lp.toFixed(3)}LP</td>
       </tr>
       
       <tr > 
           <td id='fcolumn' className='balance'>Wallet Address</td>
            <td id='tcolumn' className='balance'><input onChange={checkWallet} /></td> 
           <td id='tcolumn' className='balance'>Balance</td>
       </tr>
       </tbody>

   </table>
 

   {user.walletexits ?<Scheduler collecteddata = {{busd_price:drip_busd.price, rate_plant_lp: data.rate_plant_lp, wallet: user.wallet}}/> : null}

   </div>
   
   

    

    
   
  );

  }


export default DripGardenTools;
