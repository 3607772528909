import { createContext, useContext, useState } from "react";
import React from "react";
import Web3 from "web3";
import { Navigate } from "react-router";

const UserContext = createContext({
    user:null,
    checkWallet:()=>{},
    handleEthereum:()=>{},
    Disconnect:()=>{}
});
const USER = {
    wallet: null,
    walletexits: false,
      plants: 1,
      seeds: 3000000      
  }
  const DATA ={
    user_lp: 0,
    user_lp_per_day: 0,
    user_balance: 0,
    rate_plant_lp: 0,
    contract_balance: 0,
    seeds_per_plant: 2592000,
    seeds_per_day: 0,
    plant_per_day:0,
    plants_ready: 0,
    seeds_lost: 0,
    seeds_needed: 0,
    plant_next_date: [],
    plant_full_minute: 0,
    license: {},
      log: []

  }

  const DRIP = {
      step_calc: 0,
        price: 0,
        busd_price: 0,
        busd_reserve: 0,
        drip_price: 0,
        drip_reserve: 0,
        supply: null,
        lp_ratio: 0

  }

export function UserProvider({children}){

     const [user, setUser] = useState(USER);
     const [data, setData] = useState(DATA);
     const [drip_busd, setdrip_busd ] = useState(DRIP);

   const currentLp = ((parseInt(data.user_lp)).toFixed(2))  +  " " + "LP"
const currentdollars = ((drip_busd.price)*(data.user_lp)).toFixed(2) + " " + "$"

const bydayLP = (data.user_lp_per_day).toFixed(2) +  " " + "LP"
const bydayDollars = ((drip_busd.price)*(data.user_lp_per_day)).toFixed(2) + " " + "$" 
     
     const handleEthereum= async()=>{
        let provider;
        if (window.ethereum) {
          provider = window.ethereum;
          console.log("detected!");
          await provider.request({ method: 'eth_requestAccounts' });
          const web3 = new Web3(provider);
          const userAccount = await web3.eth.getAccounts();
          const account = userAccount[0];
          setUser(prevState => ({
            ...prevState,
             wallet: account,
               walletexits:true   
           }
           ));
     
          const chainId = await web3.eth.getChainId();
          console.log(chainId);
          if(chainId!==56){
            console.log("please change your network")
            alert("Please change your Metamask Account Network to BSC Mainnet")
            
          }
          let ethBalance = await web3.eth.getBalance(account); // Get wallet balance
          ethBalance = web3.utils.fromWei(ethBalance, 'ether'); //Convert balance to wei
          
        } else if (window.web3) {
          provider = window.web3.currentProvider;
        } else {
            alert("Please Install the MetaMask Browser Extension to Connect.")
        }
        return provider;
      }
      const checkWallet= (e)=>{
        console.log(e.target.value)
        const walletadd = e.target.value
       let address = walletadd.replaceAll(" ", "");
         
       if ( address.length != 42 || address.indexOf("0x") == -1) {alert('Input correct address'); }
      setUser(prevState => ({
         ...prevState,
          wallet: address,
            walletexits:true   
        }
        ));
              
       }
       function Disconnect(){
        setUser({walletexits:false})
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('data')
        window.localStorage.removeItem('drip')
        window.location.replace('/')
      }
   

    return(
        <UserContext.Provider value={{user,setUser,data, setData,drip_busd, setdrip_busd, handleEthereum, checkWallet, Disconnect,currentLp,currentdollars,bydayDollars,bydayLP}}>{children}</UserContext.Provider>
    )
}
export function useUserContext(){
    const {user, checkWallet, handleEthereum,} = useContext(UserContext);
    return{ user, checkWallet, handleEthereum};
}
export default UserContext;
